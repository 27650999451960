.tns-outer {
  position: relative;

  .tns-controls {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    pointer-events: none;

    [data-controls="prev"],
    [data-controls="next"] {
      background: none;
      border: none;
      padding: 0;
      margin: 0;
      pointer-events: auto;
      position: absolute;
      color: transparent;
      top: 0;
      bottom: 0;
      width: $grid-gutter-width / 2;

      @include media-breakpoint-up(sm) {

        width: $grid-gutter-width;
      }

      &:before{
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
      }
    }

    [data-controls="prev"] {
      left: -#{$grid-gutter-width / 2};

      @include media-breakpoint-up(sm) {

        left: -#{$grid-gutter-width};
      }

      &:before{
        content: url($path_images + '/arrow-left.svg');
      }
    }

    [data-controls="next"] {
      right: -#{$grid-gutter-width / 2};

      @include media-breakpoint-up(sm) {

        right: -#{$grid-gutter-width};
      }

      &:before{
        content: url($path_images + '/arrow-right.svg');
      }
    }
  }

  &.current-drag {
    .tns-controls {

      [data-controls="prev"],
      [data-controls="next"] {
        opacity: 0;
      }
    }
  }

  .tns-nav {
    display: none;
  }
}
