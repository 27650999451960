
body {
  position: relative;
  padding: $grid-gutter-width 0;
  font-family: 'Lato', sans-serif;
}

.demo-logo {
  width: 100%;
  max-width: 320px;
  margin: 0 auto $grid-gutter-width;
  display: block;
}

#mediaplatformPlayer {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  margin-bottom: $grid-gutter-width;
  &:before,
  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &:before {
    background-image: url($path_images + '/logo.svg');
    background-repeat: no-repeat;
    background-color: white;
    background-size: 320px;
    background-position: center;
    content: "";
    filter: invert(1);
  }
}


.related-videos-container{
  h1{
    text-transform: uppercase;
    font-weight: lighter;
    font-size: 13pt;
  }
}
