.container {
  @include make-container();
  @include make-container-max-widths();

  &:before {
    background-image: url($path_images + '/background.png');
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: cover;
    background-position: top center;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 60%;
    z-index: -1;

    @include media-breakpoint-up(sm) {
      background-image: url($path_images + '/background2x.png');
    }
  }
}
