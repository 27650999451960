@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "utilities/variables";

@mixin btn {
  position: absolute;
  bottom: 10px;
  width: 40px;
  height: 40px;

  &:focus {
    outline: none;
  }
  &:before {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #242B33;
    border-radius: 10px;
    z-index: 0
  }
}

body {
  font-family: 'Lato', sans-serif;
}

.mwPlayerContainer {
  .controlBarContainer {
    //[class^="icon-"], [class*=" icon-"] {
    //  font-family: 'Lato', sans-serif;
    //}

    //@include btn;


    .controlsContainer {
      .sourceSelector, .logo {
        display: none !important;
      }

      box-sizing: border-box;

      * {
        box-sizing: border-box;
      }

      position: absolute;
      left: 60px;
      bottom: 10px;
      width: calc(100% - 120px);
      height: 40px;
      border-top: none;
      box-shadow: none;
      border-radius: 10px;
      padding: 10px;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: #242B33;
        border-radius: 10px;
        z-index: 0
      }

      > * {
        z-index: 1;
        position: relative;
      }


      .btn {
        position: absolute;
        width: 20px;
        height: 20px;
        font-size: initial;

        &:focus {
          outline: none;
        }
      }


      .timers {
        font-size: 10pt;
        padding: 0;
        line-height: normal;
        top: -6px;
      }

      .scrubber {
        margin-left: 20px;
        height: 5px;
        top: -8px;

        .watched {

          border-radius: 5px;

          &:before {
            position: absolute;
            content: '';
            background-image: linear-gradient(to bottom, #3A2363, #FB9BB1);
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            border-radius: 5px;
          }
        }

        .buffered {
          opacity: initial;
          border-radius: 5px;

          &:before {
            position: absolute;
            content: '';
            background: #FAFAFA;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            border-radius: 5px;
          }
        }
      }

      .volumeControl {
        margin-left: 20px;
        width: 20px;
        height: 20px;

        .slider {
          display: none;
        }

       /* .btn { //problem with Edge

          &.icon-volume-mute:before {
            content: url($path_images + '/video-player/volume-mute.svg');
          }

          &.icon-volume-high:before {
            content: url($path_images + '/video-player/volume-high.svg');
          }
        }*/
      }


      .icon-chromecast {

        right: 10px;

        &:before {
          content: url($path_images + '/video-player/chromecast.svg');
        }
      }
    }

    .fullScreenBtn {

      @include btn;
      right: 10px;

      &:before {
        content: url($path_images + '/video-player/full-screen.svg');

      }
    }

    .playPauseBtn {
      @include btn;
      left: 10px;

      &:after {
        transition: all .5s;
      }

      &:hover {
        &:after {
          border-radius: 20px;
        }
      }

      &.icon-play:before {
        content: url($path_images + '/video-player/play.svg');
        top: calc(50% + 1px);
        left: calc(50% + 1px);
      }

      &.icon-pause:before {
        content: url($path_images + '/video-player/pause.svg');
      }

      &:after {
        background-color: #4316AC;
      }
    }
  }
}
