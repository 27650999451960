.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0; // See https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106
  word-wrap: break-word;
  background-color: $card-bg;
  background-clip: border-box;
  border: $card-border-width solid $card-border-color;
  @include border-radius($card-border-radius);
}

.card-img {
  width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
  @include border-radius($card-inner-border-radius);
}

.card {
  border-color: transparent;
  border-radius: 16px;
  overflow: hidden;

  .card-img {
    width: 100%;
    padding-top: 56.25%;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;

    &:before {
      position: absolute;
      content: '';
      background-image: url($path_images + '/video_play.svg');
      width: 22px;
      height: 22px;
      top: 10px;
      left: 10px;
      background-size: 22px;
    }

    img {
      position: absolute;
      top: -100%;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
    }
  }

  .duration {
    position: absolute;
    bottom: 8px;
    left: 8px;
    color: #fff;
    font-size: 8pt;
  }

  .title {
    position: absolute;
    bottom: 8px;
    right: 8px;
    color: #fff;
    font-size: 8pt;
    max-width: calc(100% - 50px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @include media-breakpoint-up(sm) {

    .duration {

      bottom: 10px;
      left: 10px;
      font-size: 12pt;
    }

    .title {

      bottom: 14px;
      right: 10px;
      font-size: 8pt;
      max-width: calc(100% - 70px);
    }
  }
}
