/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

body {
  position: relative;
  padding: 30px 0;
  font-family: 'Lato', sans-serif;
}

.demo-logo {
  width: 100%;
  max-width: 320px;
  margin: 0 auto 30px;
  display: block;
}

#mediaplatformPlayer {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  margin-bottom: 30px;
}

#mediaplatformPlayer:before,
#mediaplatformPlayer > iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#mediaplatformPlayer:before {
  background-image: url("../images/logo.svg");
  background-repeat: no-repeat;
  background-color: white;
  background-size: 320px;
  background-position: center;
  content: "";
  filter: invert(1);
}

.related-videos-container h1 {
  text-transform: uppercase;
  font-weight: lighter;
  font-size: 13pt;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container:before {
  background-image: url("../images/background.png");
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
  background-position: top center;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 60%;
  z-index: -1;
}

@media (min-width: 576px) {
  .container:before {
    background-image: url("../images/background2x.png");
  }
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}

.card {
  border-color: transparent;
  border-radius: 16px;
  overflow: hidden;
}

.card .card-img {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.card .card-img:before {
  position: absolute;
  content: '';
  background-image: url("../images/video_play.svg");
  width: 22px;
  height: 22px;
  top: 10px;
  left: 10px;
  background-size: 22px;
}

.card .card-img img {
  position: absolute;
  top: -100%;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}

.card .duration {
  position: absolute;
  bottom: 8px;
  left: 8px;
  color: #fff;
  font-size: 8pt;
}

.card .title {
  position: absolute;
  bottom: 8px;
  right: 8px;
  color: #fff;
  font-size: 8pt;
  max-width: calc(100% - 50px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 576px) {
  .card .duration {
    bottom: 10px;
    left: 10px;
    font-size: 12pt;
  }
  .card .title {
    bottom: 14px;
    right: 10px;
    font-size: 8pt;
    max-width: calc(100% - 70px);
  }
}

.tns-outer {
  position: relative;
}

.tns-outer .tns-controls {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  pointer-events: none;
}

.tns-outer .tns-controls [data-controls="prev"],
.tns-outer .tns-controls [data-controls="next"] {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  pointer-events: auto;
  position: absolute;
  color: transparent;
  top: 0;
  bottom: 0;
  width: 15px;
}

@media (min-width: 576px) {
  .tns-outer .tns-controls [data-controls="prev"],
  .tns-outer .tns-controls [data-controls="next"] {
    width: 30px;
  }
}

.tns-outer .tns-controls [data-controls="prev"]:before,
.tns-outer .tns-controls [data-controls="next"]:before {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.tns-outer .tns-controls [data-controls="prev"] {
  left: -15px;
}

@media (min-width: 576px) {
  .tns-outer .tns-controls [data-controls="prev"] {
    left: -30px;
  }
}

.tns-outer .tns-controls [data-controls="prev"]:before {
  content: url("../images/arrow-left.svg");
}

.tns-outer .tns-controls [data-controls="next"] {
  right: -15px;
}

@media (min-width: 576px) {
  .tns-outer .tns-controls [data-controls="next"] {
    right: -30px;
  }
}

.tns-outer .tns-controls [data-controls="next"]:before {
  content: url("../images/arrow-right.svg");
}

.tns-outer.current-drag .tns-controls [data-controls="prev"],
.tns-outer.current-drag .tns-controls [data-controls="next"] {
  opacity: 0;
}

.tns-outer .tns-nav {
  display: none;
}

body {
  font-family: 'Lato', sans-serif;
}

.mwPlayerContainer .controlBarContainer .controlsContainer {
  box-sizing: border-box;
  position: absolute;
  left: 60px;
  bottom: 10px;
  width: calc(100% - 120px);
  height: 40px;
  border-top: none;
  box-shadow: none;
  border-radius: 10px;
  padding: 10px;
}

.mwPlayerContainer .controlBarContainer .controlsContainer .sourceSelector, .mwPlayerContainer .controlBarContainer .controlsContainer .logo {
  display: none !important;
}

.mwPlayerContainer .controlBarContainer .controlsContainer * {
  box-sizing: border-box;
}

.mwPlayerContainer .controlBarContainer .controlsContainer:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #242B33;
  border-radius: 10px;
  z-index: 0;
}

.mwPlayerContainer .controlBarContainer .controlsContainer > * {
  z-index: 1;
  position: relative;
}

.mwPlayerContainer .controlBarContainer .controlsContainer .btn {
  position: absolute;
  width: 20px;
  height: 20px;
  font-size: initial;
}

.mwPlayerContainer .controlBarContainer .controlsContainer .btn:focus {
  outline: none;
}

.mwPlayerContainer .controlBarContainer .controlsContainer .timers {
  font-size: 10pt;
  padding: 0;
  line-height: normal;
  top: -6px;
}

.mwPlayerContainer .controlBarContainer .controlsContainer .scrubber {
  margin-left: 20px;
  height: 5px;
  top: -8px;
}

.mwPlayerContainer .controlBarContainer .controlsContainer .scrubber .watched {
  border-radius: 5px;
}

.mwPlayerContainer .controlBarContainer .controlsContainer .scrubber .watched:before {
  position: absolute;
  content: '';
  background-image: linear-gradient(to bottom, #3A2363, #FB9BB1);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 5px;
}

.mwPlayerContainer .controlBarContainer .controlsContainer .scrubber .buffered {
  opacity: initial;
  border-radius: 5px;
}

.mwPlayerContainer .controlBarContainer .controlsContainer .scrubber .buffered:before {
  position: absolute;
  content: '';
  background: #FAFAFA;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 5px;
}

.mwPlayerContainer .controlBarContainer .controlsContainer .volumeControl {
  margin-left: 20px;
  width: 20px;
  height: 20px;
  /* .btn { //problem with Edge

          &.icon-volume-mute:before {
            content: url($path_images + '/video-player/volume-mute.svg');
          }

          &.icon-volume-high:before {
            content: url($path_images + '/video-player/volume-high.svg');
          }
        }*/
}

.mwPlayerContainer .controlBarContainer .controlsContainer .volumeControl .slider {
  display: none;
}

.mwPlayerContainer .controlBarContainer .controlsContainer .icon-chromecast {
  right: 10px;
}

.mwPlayerContainer .controlBarContainer .controlsContainer .icon-chromecast:before {
  content: url("../images/video-player/chromecast.svg");
}

.mwPlayerContainer .controlBarContainer .fullScreenBtn {
  position: absolute;
  bottom: 10px;
  width: 40px;
  height: 40px;
  right: 10px;
}

.mwPlayerContainer .controlBarContainer .fullScreenBtn:focus {
  outline: none;
}

.mwPlayerContainer .controlBarContainer .fullScreenBtn:before {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mwPlayerContainer .controlBarContainer .fullScreenBtn:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #242B33;
  border-radius: 10px;
  z-index: 0;
}

.mwPlayerContainer .controlBarContainer .fullScreenBtn:before {
  content: url("../images/video-player/full-screen.svg");
}

.mwPlayerContainer .controlBarContainer .playPauseBtn {
  position: absolute;
  bottom: 10px;
  width: 40px;
  height: 40px;
  left: 10px;
}

.mwPlayerContainer .controlBarContainer .playPauseBtn:focus {
  outline: none;
}

.mwPlayerContainer .controlBarContainer .playPauseBtn:before {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mwPlayerContainer .controlBarContainer .playPauseBtn:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #242B33;
  border-radius: 10px;
  z-index: 0;
}

.mwPlayerContainer .controlBarContainer .playPauseBtn:after {
  transition: all .5s;
}

.mwPlayerContainer .controlBarContainer .playPauseBtn:hover:after {
  border-radius: 20px;
}

.mwPlayerContainer .controlBarContainer .playPauseBtn.icon-play:before {
  content: url("../images/video-player/play.svg");
  top: calc(50% + 1px);
  left: calc(50% + 1px);
}

.mwPlayerContainer .controlBarContainer .playPauseBtn.icon-pause:before {
  content: url("../images/video-player/pause.svg");
}

.mwPlayerContainer .controlBarContainer .playPauseBtn:after {
  background-color: #4316AC;
}
